import { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  CHARGE_BACK_API,
  GET_REPORT_BY_CONDITION_API,
  MULTIPLE_FILTER_API,
  MULTILINE_CHART_API,
  PIE_CHART_API,
  SETTLEMENT_REPORT_API,
  GET_REPORT_API,
  VERIFY_VENDER_AADHAAR_OTP,
  VERIFY_VENDER_PAN,
  SAVE_VENDER_DETAILS,
  ACC_NUM_IFSC_VERIFY_API,
  IFSC_VERIFY_API,
  VERIFY_UPI_API,
  VERIFY_VENDER_EMAIL,
  VERIFY_VENDER_MOBILE,
  VERIFY_VENDER_AADHAAR,
  FETCH_ALL_VENDERS_BY_MID,
  FETCH_VENDER_DETAILS_BY_VENDER_ID,
  VERIFY_MERCHANT_ONBOARD_EMAIL,
  VERIFY_MERCHANT_ONBOARD_MOBILE,
  VIEW_PAYOUT_REQUEST_UPI,
  SWIPELINC_API,
  CHECK_FILE_IS_PDF,
  UPLOAD_MERCHANT_DOCS,
  UPDATE_MERCHANT_BANK_DETAILS,
  VIEW_E_KYC_REPORT,
  PAYOUT_REQUEST_DELETE,
  GET_ALL_PAYOUT_REQUEST,
  SAVE_PAYOUT_REQUEST,
  GET_DASHBOARD_AMOUNT_API,
  WITHDRAW_REQUEST_FETCH,
  SETTLEMENT_REPORT_FETCH,
  SAVE_MISSING_CALLBACK,
  SETTLEMENT_MULTIPLE_FILTER_API,
  GUARANTEE_REPORT_FETCH,
  CHARGEBACK_MULTIPLE_FILTER_API,
  WITHDRAW_MULTIPLE_FILTER_API,
  testURL,
  GURANTEE_MULTIPLE_FILTER_API,
  VIEW_PAYOUT_REQUEST_UPI_BY_MID,
  BALANCE_FETCH,
  GET_NOTIFICATION_BY_MID,
  DISABLE_NOTIFICATION_BY_ID,
  PAYOUT_MULTIPLE_FILTER_API,
  CHECK_THIRD_PARTY_API_STATUS,
  CHECK_EMAIL_IS_VERIFIED_OR_NOT,
  CHECK_MOBILE_IS_VERIFIED_OR_NOT,
  UPLOAD_CALLBACK_CSV,
  FIND_BY_MID_AND_ORDERNO,
  FIND_BY_TRANSATION_ID,
  FETCH_ALL_VENDERS_BY_STATUS_AND_MID,
  PENDING_WITHDRAW_REQUEST_FETCH,
  GET_ALL_RECONCIALIATION_DATA,
  GET_ALL_SETTLEMET_ADJUSTMENT_DATA,
  FETCH_MERCHANT_PROFILE,
  GET_RECONCEALITION_DATE,
  FETCH_RELEASE_BY_MID,
  FETCH_LIVE_TRACKING,
  UPLOAD_PAYOUT_TXN_CALLBACK_CSV,
  GET_QR_STRING,
  RECONCILIATION_MULTIPLE_FILTER_API,
  GET_ALL_ORGANISATIONAL_TYPE,
  WALLET_BALANCE_REPORT_API,
  FETCH_HOLD_BY_MID,
  FETCH_DEPOSIT_BY_MID,
  VIRTUAL_TERMINAL_REQUEST_SUBMIT,
  FRM_BLOCK_USER,
  FETCH_FRM_USERS,
  USER_UPDATE,
  GET_STATIC_QR,
  ACCEPT_OR_REJECT,
  BOX_DATA_BY_CONDITION,
} from "../utils/constant";
import { LOGIN_API } from "../utils/constant";
import jwtDecode from "jwt-decode";
import {
  setPrivilagesData,
  setUserType,
} from "../store/slices/privilagesSlice";
import { useDispatch } from "react-redux";

/*Merchant Login API Call*/

const schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  userName: Yup.string().required("Username is required"),
});

export const useLoginForm = () => {
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if username or password is empty
    if (!userName || !password) {
      setIsFormSubmitted(true);
      return;
    }

    try {
      await schema.validate({ password, userName }, { abortEarly: false });
      const response = await axios.post(LOGIN_API, { password, userName });

      if (response.data.statusCode === 200) {
        const token = response?.data?.token;
        const privilages =
          response?.data?.data?.merchantPrivledgeAndSubpriledge;
        const privilagesData = privilages?.map(
          (item) => item.privilegeSubTypeId
        );

        // if (token) {
        //   navigate("/Dashboard");
        // }
        const data = response?.data?.data;

        // localStorage.setItem("userData", JSON.stringify(response.data));
        // localStorage.setItem("mid", JSON.stringify(response.data.data.mid));
        // localStorage.setItem(
        //   "merchantName",
        //   JSON.stringify(response.data.data.merchantName)
        // );
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("privilagesData", JSON.stringify(privilagesData));
        dispatch(setPrivilagesData(privilagesData));
        dispatch(setUserType(data.userType));
        localStorage.setItem(
          "typeOfServices",
          JSON.stringify(data.typeOfServices)
        );
        // localStorage.setItem("paymentType", JSON.stringify(paymentType));
        localStorage.setItem("data", JSON.stringify(data));
        const stageData = localStorage.getItem("data");
        const stage = stageData ? JSON.parse(stageData) : null;

        // console.log(stage && stage.stage === "stage2");

        let val = stage && stage.stage2 === "Submitted";
        setShowPopUp(val);

        let value = stage && stage.stage === "stage2";
        console.log("value:", value);

        if (!val) {
          value ? navigate("/FirstStage") : navigate("/Dashboard");
        }
        // if (stage && stage.stage === "stage2") {
        //   navigate("/FirstStage");
        // }
        // navigate("/Dashboard");
      } else {
        setErrorMessages([response.data.message]);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrorMessages(validationErrors);
      } else {
        setError(error.message);
      }
    }
  };

  return {
    userName,
    setuserName,
    password,
    setPassword,
    error,
    errorMessages,
    handleSubmit,
    isFormSubmitted,
    showPopUp,
    setShowPopUp,
  };
};

// get chargeback data
export async function getChargeBackData(
  mid,
  token,
  setShowLoader,
  setCustomMultiApiResponse
) {
  try {
    setShowLoader(true);
    const response = await axios.get(CHARGE_BACK_API + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    setShowLoader(false);
    if (response.data.statusCode === 200) {
      const data2 = response.data.data;
      setCustomMultiApiResponse(data2);
    } else if (response.data.statusCode === 400) {
      setCustomMultiApiResponse([]);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function useMultiLineChartDataFetcher(mid, token) {
  try {
    const response = await axios.get(MULTILINE_CHART_API + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to fetch data");
    }

    return response?.data?.responseData;
  } catch (error) {
    throw new Error(error?.message);
  }
}

/* Dashboard Pie-Chart API Response*/
export const usePieChartDataFetcher = (mid, token) => {
  const [chartData, setChartData] = useState();
  const getData = async () => {
    const response = await axios.get(PIE_CHART_API + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    setChartData(response?.data?.responseData);
  };
  useEffect(() => {
    getData();
  }, []); //eslint-disable-line
  return chartData;
};

/*Settlement-Report API Response*/
export const useSettlementReportDataFetcher = (mid, token) => {
  const [listData, setListData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []); //eslint-disable-line

  const fetchData = async () => {
    try {
      const response = await axios.get(SETTLEMENT_REPORT_API + mid, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });

      if (response.data.statusCode === 200) {
        const data2 = response.data.data;
        setListData(data2);
      } else if (response.data.statusCode === 400) {
        setListData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return listData;
};

/*Advanced Search-Pop-Up API Response(Report By Condition)*/

export const useReportByCondition = async (
  emailSearchValue,
  orderNumberSearchValue,
  transactionIdSearchValue,
  startDate,
  endDate,
  mobileSearchValue,
  payerVPASearchValue,
  custRefNoSearchValue,
  mid,
  token,
  setApiResponse,
  setCustomApiResponse,
  setShowLoader
) => {
  try {
    if (
      emailSearchValue ||
      orderNumberSearchValue ||
      transactionIdSearchValue ||
      mobileSearchValue ||
      payerVPASearchValue ||
      custRefNoSearchValue
    ) {
      console.log(payerVPASearchValue);
      // console.log(mid);
      setShowLoader(true);
      console.log(transactionIdSearchValue);
      const response = await axios.post(
        GET_REPORT_BY_CONDITION_API,
        {
          email: emailSearchValue ? emailSearchValue.trim() : "",
          orderNo: orderNumberSearchValue ? orderNumberSearchValue.trim() : "",
          txnId: transactionIdSearchValue
            ? transactionIdSearchValue.trim()
            : "",
          startDate: startDate ? startDate : "",
          endDate: endDate ? endDate : "",
          mobile: mobileSearchValue ? mobileSearchValue.trim() : "",
          payerVpa: "",
          custRefNo: custRefNoSearchValue ? custRefNoSearchValue.trim() : "",
          mid: mid,
          userVpa: payerVPASearchValue ? payerVPASearchValue.trim() : "",
          paymentType: "",
          txnDate: "",
          terminalId: "",
          amount: "",

          custIpAddress: "",
          custLocation: "",
          ifsc: "",
          bankLocation: "",
        },
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      setShowLoader(false);
      // console.log(response?.data?.responseData);
      setApiResponse(response?.data?.responseData);

      setCustomApiResponse(response?.data?.responseData);
    } else {
      setApiResponse(null);
      setCustomApiResponse(null);
    }
  } catch (error) {
    console.log(error);
  }
};

//MULTIPLEFILTR
export const USE_MULTIPLE_FILTER_API = async (
  amountSearchValue,
  bankLocationSearchValue,
  custLocationSearchValue,
  dateSearchValue,
  endAmountSearchValue,
  endDateSearchValue,
  greaterAmountSearchValue,
  ipAddress,
  lessAmountSearchValue,
  mid,
  payerVPASearchValue,
  paymentTypeSearchvalue,
  startAmountSearchValue,

  startDateSearchValue,
  token,
  transectionStatusSearchValue,
  setMultiApiResponse,
  setCustomMultiApiResponse,
  setShowLoader
) => {
  try {
    // console.log(mid + "mid");
    // console.log(amountSearchValue + "amount");
    // console.log(transectionStatusSearchValue + "status");
    // console.log(dateSearchValue + "dateSearchValue");
    // console.log(endAmountSearchValue + " endAmountSearchValue");
    // console.log(greaterAmountSearchValue + " greaterAmountSearchValue");
    // console.log(lessAmountSearchValue);
    // console.log(payerVPASearchValue + " payerVPASearchValue");
    // console.log(startAmountSearchValue + " startAmountSearchValue");
    // console.log(startDateSearchValue + " startDateSearchValue");
    // console.log(endDateSearchValue + " endDateSearchValue");
    // console.log(paymentTypeSearchvalue + " paymenttypesearchvalue");
    if (1) {
      setShowLoader(true);
      const response = await axios.post(
        MULTIPLE_FILTER_API,
        {
          amount: amountSearchValue ? amountSearchValue.trim() : 0,
          bankLocation: bankLocationSearchValue
            ? bankLocationSearchValue.trim()
            : "",
          customerLocation: custLocationSearchValue
            ? custLocationSearchValue.trim()
            : "",
          date: dateSearchValue ? dateSearchValue.trim() : "",
          endAmount: endAmountSearchValue ? endAmountSearchValue.trim() : 0,
          endDate: endDateSearchValue ? endDateSearchValue.trim() : "",
          greterAmount: greaterAmountSearchValue
            ? greaterAmountSearchValue.trim()
            : 0,
          ipAddress: ipAddress.trim(),
          lessAmount: lessAmountSearchValue ? lessAmountSearchValue.trim() : 0, // Include mobile value
          mid: mid,
          payerVpa: payerVPASearchValue ? payerVPASearchValue.trim() : "",
          paymentType: paymentTypeSearchvalue
            ? paymentTypeSearchvalue.trim()
            : "",
          startAmount: startAmountSearchValue
            ? startAmountSearchValue.trim()
            : 0,
          startDate: startDateSearchValue ? startDateSearchValue.trim() : "",
          // token:token,
          transactionStatus: transectionStatusSearchValue
            ? transectionStatusSearchValue.trim()
            : "",
        },
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      setShowLoader(false);
      // console.log(response?.data?.responseData, "jhgjgjhfhgcfgh")

      console.log("sss", response?.data.statusCode === 304);

      // setMultiApiResponse(response?.data?.responseData  );
      if (response?.data.statusCode === 304) {
        setCustomMultiApiResponse([]);
        setMultiApiResponse([]);
        return;
      }
      setCustomMultiApiResponse(
        response?.data?.responseData ? response?.data?.responseData : []
      );
    } else {
      setMultiApiResponse(null);
      setCustomMultiApiResponse(null);
    }

    // console.log(response)
    // setCustomMultiApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};

export const USE_MULTIPLE_FILTER_BOX_API = async (
  dateSearchValue,

  endDateSearchValue,

  mid,

  startDateSearchValue,
  token,

  setBoxData,

  setShowLoader
) => {
  try {
    console.log(setBoxData);

    if (1) {
      setShowLoader(true);
      const response = await axios.post(
        BOX_DATA_BY_CONDITION,
        {
          date: dateSearchValue ? dateSearchValue.trim() : "",

          endDate: endDateSearchValue ? endDateSearchValue.trim() : "",

          mid: mid,

          startDate: startDateSearchValue ? startDateSearchValue.trim() : "",
        },
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      setShowLoader(false);
      // console.log(response?.data?.responseData, "jhgjgjhfhgcfgh")

      console.log("sss", response);

      // setMultiApiResponse(response?.data?.responseData  );
      if (response?.data.statusCode === 304) {
        setBoxData([]);

        return;
      }

      setBoxData(response?.data?.data ? response?.data?.data : []);
    } else {
      setBoxData(null);
    }

    // console.log(response)
    // setCustomMultiApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};
//MULTIPLEFILTR
/* Report Api response */

export const useReportDataFetcher = async (mid, token, setShowLoader) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_REPORT_API,
      {
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    if (
      response.data.statusCode === 304 ||
      response.data.responseData === "No Record Found"
    ) {
      throw new Error("No records found");
    }

    return response.data.responseData;
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

export const findAllVendors = async (mid, token) => {
  try {
    const response = await axios.post(
      `${SWIPELINC_API}MerchantPanel/merchant/vendor/findAllVendor/${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    if (
      response.data.statusCode === 304 ||
      response.data.Data === "No Record Found"
    ) {
      throw new Error("No records found");
    }

    return response.data.Data;
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

// verify account number and ifsc code
export const accountNumberIfscCodeVerifier = async (
  ifscCode,
  accountNumber,
  token
) => {
  try {
    const res = await axios.post(
      ACC_NUM_IFSC_VERIFY_API,
      {
        ifsc: ifscCode,
        accountNumber,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

// get bank details by ifsc code
export const ifscCodeVerifier = async (ifscCode) => {
  try {
    const res = await axios.post(IFSC_VERIFY_API, { ifscCode });
    return { data: res?.data?.data, status: res?.data?.statusCode };
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

// VERIFY_UPI_API
export const verifyUpiId = async (upiId) => {
  try {
    const res = await axios.post(VERIFY_UPI_API, { upiId });
    return { data: res?.data?.data, status: res?.data?.statusCode };
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

// verify vender registration email
export const verifyVenderRegEmail = async (email, token, mid) => {
  const payload = {
    data: email,
    mid,
  };
  try {
    const res = await axios.post(`${VERIFY_VENDER_EMAIL}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// verify vender registration mobile
export const verifyVenderRegMobileNum = async (mobileNumber, token, mid) => {
  const payload = {
    data: mobileNumber,
    mid,
  };
  try {
    const res = await axios.post(`${VERIFY_VENDER_MOBILE}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res.data,"+++++++");
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const handleAadharSendOtp = async (aadharNumber) => {
  try {
    const response = await axios.post(`${VERIFY_VENDER_AADHAAR}`, {
      aadhaarNumber: aadharNumber,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const handleVerifyAadharOtp = async (otpDataInput) => {
  try {
    const response = await axios.post(
      `${VERIFY_VENDER_AADHAAR_OTP}`,
      otpDataInput
    );
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

/* verify vender registration pan card and send otp */
export const verifyPan = async (panNumber) => {
  try {
    const res = await axios.post(VERIFY_VENDER_PAN, { panNumber });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// save the vender details
export const saveVenderDetails = async (venderData, token) => {
  try {
    const res = await axios.post(SAVE_VENDER_DETAILS, venderData, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

/* get all vender*/
export const fetchVenderByMid = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_ALL_VENDERS_BY_MID}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchVenderByStatusWithMids = async (status, mid, token) => {
  try {
    const res = await axios.post(
      `${FETCH_ALL_VENDERS_BY_STATUS_AND_MID}`,
      { parentMid: mid, status },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// fetch vender details by vender id
export const fetchVenderDetailsByVenderId = async (venderId, token) => {
  try {
    const res = await axios.get(
      `${FETCH_VENDER_DETAILS_BY_VENDER_ID}${venderId}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(res.data);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// --------MERCHANT ONBOARDING------------

// verify merchant onboard email is pre verified or not
export const verifyMerchantEmailIsVerifiedOrNot = async (email) => {
  try {
    const response = await axios.post(`${VERIFY_MERCHANT_ONBOARD_EMAIL}`, {
      email,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// verify merchant onboard mobile is pre verified or not
export const verifyMerchantMobileIsVerifiedOrNot = async (mobile) => {
  try {
    const response = await axios.post(`${VERIFY_MERCHANT_ONBOARD_MOBILE}`, {
      mobile,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const viewPayoutRequest = async (payload, token) => {
  try {
    const res = await axios.post(VIEW_PAYOUT_REQUEST_UPI, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const viewPayoutRequestByMid = async (mid, token) => {
  try {
    const res = await axios.get(`${VIEW_PAYOUT_REQUEST_UPI_BY_MID}/${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// check file is pdf or not
export const checkIsFileIsPDF = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${CHECK_FILE_IS_PDF}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response?.data?.statusCode);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// upload merchant documents
export const uploadMerchantDocs = async (mid, docsName, docsFile) => {
  // alert("called");
  try {
    const formData = new FormData();
    formData.append("mid", mid);

    // formData.append("fileName", [...docsName]);
    // formData.append("pdfFiles", docsFile);

    docsFile.forEach((file) => {
      formData.append(`pdfFiles`, file);
    });
    docsName.forEach((name) => {
      formData.append(`fileName`, name);
    });

    const response = await axios.post(`${UPLOAD_MERCHANT_DOCS}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// UPDATE MERCHANT BANK DETAILS
export const updateMerchantBankDetails = async (payload) => {
  try {
    const response = await axios.put(
      `${UPDATE_MERCHANT_BANK_DETAILS}`,
      payload
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const viewEkycReport = async (payload, token) => {
  try {
    const res = await axios.post(VIEW_E_KYC_REPORT, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const savePayoutRequest = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_PAYOUT_REQUEST, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllPayoutRequest = async (mid, token) => {
  try {
    const res = await axios.get(GET_ALL_PAYOUT_REQUEST + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const payoutRequestDelete = async (id, token) => {
  try {
    const res = await axios.delete(PAYOUT_REQUEST_DELETE + id, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getDashboardAmountApi = async ({ mid, token }) => {
  try {
    console.log(mid, token);
    const res = await axios.get(GET_DASHBOARD_AMOUNT_API + mid, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data?.responseData;
  } catch (error) {
    throw new Error(error);
  }
};
export const getWithdrawRequestByMid = async (mid, token) => {
  try {
    const res = await axios.get(WITHDRAW_REQUEST_FETCH + mid, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPendingWithdrawRequestByMid = async (mid, token) => {
  try {
    const res = await axios.get(PENDING_WITHDRAW_REQUEST_FETCH + mid, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSettlementReportByMid = async (mid, token) => {
  try {
    const res = await axios.get(SETTLEMENT_REPORT_FETCH + mid, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const AcceptOrRejectPayin = async (payload, token) => {
  try {
    const res = await axios.post(ACCEPT_OR_REJECT, payload, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveMissingCallBack = async (mid, token, orderId) => {
  console.log("mid", mid);
  console.log("token", token);
  console.log("orderId", orderId);

  try {
    const res = await axios.post(
      SAVE_MISSING_CALLBACK,
      { mid, orderId },
      {
        headers: {
          Authentication: `Bearer ${token}`, // Corrected from "Authentication" to "Authorization"
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const USE_SETTLEMENT_MULTIPLE_FILTER_API = async (
  startAmount,
  endAmount,
  payinAmount,
  startPayinAmount,
  endPayinAmount,
  startDate,
  endDate,
  txnId,
  orderNo,
  vpa,
  mid,
  token,
  setShowLoader,
  setCustomMultiApiResponse
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      SETTLEMENT_MULTIPLE_FILTER_API,
      {
        endAmount: endAmount ? endAmount.trim() : 0,
        endDate: endDate,
        endPayinAmount: endPayinAmount ? endPayinAmount : 0,
        mid: mid,
        orderNo: orderNo,
        payinAmount: payinAmount ? payinAmount : 0,
        startAmount: startAmount ? startAmount : 0,
        startDate: startDate,
        startPayinAmount: startPayinAmount ? startPayinAmount : 0,
        txnId: txnId ? txnId.trim() : "",
        vpa: vpa ? vpa : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "jhgjgjhfhgcfgh");
    setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_WITHDRAW_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  startDate1,
  endDate1,
  txnDate,
  Date,
  utr,
  status,
  mid,
  token,
  setShowLoader,
  setCustomMultiApiResponse
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? amount.trim() : 0,
        startAmount: startAmount ? startAmount.trim() : 0,
        endAmount: endAmount ? endAmount.trim() : 0,
        startDate: startDate ? startDate.trim() : "",
        endDate: endDate ? endDate.trim() : "",
        createStartDate: startDate1 ? startDate1.trim() : "",
        createEndDate: endDate1 ? endDate1.trim() : "",

        txnDate: txnDate ? txnDate.trim() : "",
        createDate: Date ? Date.trim() : "",
        utr: utr ? utr.trim() : "",
        status: status ? status.trim() : "",
        mid: mid,
        // txnDate:"",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "jhgjgjhfhgcfgh");
    setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_CHARGEBACK_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  startDate1,
  endDate1,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  UPItransactionID,
  mid,
  token,
  setCustomMultiApiResponse,
  setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount.trim() : "",
        startAmount: startAmount ? startAmount.trim() : "",
        endAmount: endAmount ? endAmount.trim() : "",
        startDate: startDate ? startDate.trim() : "",
        endDate: endDate ? endDate.trim() : "",
        transactionDate: transactionDate ? transactionDate.trim() : "",
        chargeBackUploadStartDate: startDate1 ? startDate1.trim() : "",
        chargeBackUploadEndDate: endDate1 ? endDate1.trim() : "",
        chargeBackUploadDate: chargeBackUploadDate
          ? chargeBackUploadDate.trim()
          : "",
        mid: mid,
        email: email ? email.trim() : "",
        mobile: mobile ? mobile.trim() : "",
        payerVpa: payerVpa ? payerVpa.trim() : "",
        rrn: rrn ? rrn.trim() : "",
        transactionUid: transactionUid ? transactionUid.trim() : "",
        upiTransactionId: UPItransactionID ? UPItransactionID.trim() : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    if (response?.data?.statusCode === 200) {
      setCustomMultiApiResponse(response?.data?.data);
    } else {
      setCustomMultiApiResponse([]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const USE_GURANTEE_MULTIPLE_FILTER_API = async (
  startGuarantyAmount,
  endGuarantyAmount,
  guarantyAmount,
  netAmount,
  startDate,
  endDate,
  transactionDate,
  utrNumber,
  mid,
  token,
  setCustomMultiApiResponse,
  setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GURANTEE_MULTIPLE_FILTER_API,
      {
        startGuarantyAmount: startGuarantyAmount
          ? startGuarantyAmount.trim()
          : "",
        endGuarantyAmount: endGuarantyAmount ? endGuarantyAmount.trim() : "",
        guarantyAmount: guarantyAmount ? guarantyAmount.trim() : "",
        netAmount: netAmount ? netAmount.trim() : "",
        startDate: startDate ? startDate.trim() : "",
        enddate: endDate ? endDate.trim() : "",
        transactionDate: transactionDate ? transactionDate.trim() : "",
        utrNumber: utrNumber ? utrNumber.trim() : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_PAYOUT_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  RefID,
  txnId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  setCustomMultiApiResponse,
  setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      PAYOUT_MULTIPLE_FILTER_API,
      {
        amount: amount ? amount.trim() : "",
        startAmount: startAmount ? startAmount.trim() : "",
        endAmount: endAmount ? endAmount.trim() : "",
        createDate: createDate ? createDate.trim() : "",
        startDate: startDate ? startDate.trim() : "",
        endDate: endDate ? endDate.trim() : "",
        email: email ? email.trim() : "",
        mobile: mobile ? mobile.trim() : "",
        referenceId: RefID ? RefID.trim() : "",
        txnId: txnId ? txnId.trim() : "",
        rrn: rrn ? rrn.trim() : "",
        txnStatus: txnStatus ? txnStatus.trim() : "",
        txnType: transferType ? transferType.trim() : "",
        upi: upi ? upi.trim() : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const getGuaranteeReportByMid = async (mid, token) => {
  try {
    const res = await axios.get(GUARANTEE_REPORT_FETCH + mid, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const balanceFetchByMid = async (mid, token) => {
  try {
    const res = await axios.get(BALANCE_FETCH + mid, {
      headers: {
        Authentication: "Bearer " + token, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getNotificationByMid = async (mid, token) => {
  console.log("token", token);

  try {
    const res = await axios.get(`${GET_NOTIFICATION_BY_MID}/${mid}`, {
      headers: {
        Authentication: `Bearer ${token}`, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const disableNotificationById = async (id, token) => {
  console.log("token", token);

  try {
    const res = await axios.put(`${DISABLE_NOTIFICATION_BY_ID}${id}`, "", {
      headers: {
        Authentication: `Bearer ${token}`, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const checkEmailIsVerifiedOrNot = async (email, setShowLoader) => {
  try {
    setShowLoader(true);
    const res = await axios.post(CHECK_EMAIL_IS_VERIFIED_OR_NOT, { email });
    // console.log(res.data);
    setShowLoader(false);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const checkMobileIsVerifiedOrNot = async (mobile, setShowLoader) => {
  try {
    setShowLoader(true);
    const res = await axios.post(CHECK_MOBILE_IS_VERIFIED_OR_NOT, { mobile });
    // console.log(res.data);
    setShowLoader(false);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllThirdPartyServiceStatus = async () => {
  try {
    // setShowLoader(true)
    const res = await axios.get(CHECK_THIRD_PARTY_API_STATUS);
    // console.log(res.data.data);
    // setShowLoader(false)
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadCallbackCSV = async (file, mid, token) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("mid", mid);
    const response = await axios.post(`${UPLOAD_CALLBACK_CSV}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Authentication: `Bearer ${token}`,
      },
    });
    // console.log(response.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const uploadPayoutTxnCallbackCSV = async (file, mid, token) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("mid", mid);
    const response = await axios.post(
      `${UPLOAD_PAYOUT_TXN_CALLBACK_CSV}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authentication: `Bearer ${token}`,
        },
      }
    );
    // console.log(response.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const findByMidAndOrderNo = async (payload, token) => {
  try {
    const res = await axios.post(FIND_BY_MID_AND_ORDERNO, payload, {
      headers: {
        Authentication: `Bearer ${token}`, // Corrected from "Authentication" to "Authorization"
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getViewResponseByTxnAndRefId = async (payload, token) => {
  try {
    const res = await axios.post(FIND_BY_TRANSATION_ID, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllReconcialitionData = async (token) => {
  try {
    const res = await axios.get(GET_ALL_RECONCIALIATION_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllsettlementAdjustmentReport = async (mid, token) => {
  try {
    const res = await axios.get(`${GET_ALL_SETTLEMET_ADJUSTMENT_DATA}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchMerchantProfileData = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_MERCHANT_PROFILE}${mid} `, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchReconceliationDate = async (token) => {
  try {
    const res = await axios.get(`${GET_RECONCEALITION_DATE}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchReleaseByMid = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_RELEASE_BY_MID}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchLiveTrackingReport = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_LIVE_TRACKING}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchQrString = async (payload, token) => {
  try {
    const res = await axios.post(GET_QR_STRING, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const VIRTUAL_TERMINAL_REQUEST_SUBMIT_API = async (payload, token) => {
  try {
    const res = await axios.post(VIRTUAL_TERMINAL_REQUEST_SUBMIT, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const USE_RECONCILIATION_MULTIPLE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  payerVpa,
  rrn,
  startAmount,
  startDate,
  txnId,
  token,
  setCustomMultiApiResponse,
  setShowLoader
) => {
  try {
    setShowLoader(true);

    const response = await axios.post(
      RECONCILIATION_MULTIPLE_FILTER_API,
      {
        amount: amount ? amount.trim() : 0,
        endAmount: endAmount ? endAmount.trim() : 0,
        endDate: endDate ? endDate.trim() : "",
        mid: mid,
        payerVpa: payerVpa ? payerVpa.trim() : "",
        rrn: rrn ? rrn.trim() : "",
        startAmount: startAmount ? startAmount.trim() : 0,
        startDate: startDate ? startDate.trim() : "",
        txnId: txnId ? txnId.trim() : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.statusCode === 200 ? response?.data?.data : []);
    setCustomMultiApiResponse(
      response?.data?.statusCode === 200 ? response?.data?.data : []
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllWalletBalanceReport = async (mid, token) => {
  try {
    const res = await axios.get(`${WALLET_BALANCE_REPORT_API}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log("response is:", res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchHoldByMid = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_HOLD_BY_MID}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDepositByMid = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_DEPOSIT_BY_MID}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const frmBlockVPA = async (payload, token) => {
  try {
    const res = await axios.post(`${FRM_BLOCK_USER}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const SaveFunctionForAllPostApiCall = async (url, payload, token) => {
  try {
    const res = await axios.post(url, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const GetStaticQr = async (token) => {
  try {
    const res = await axios.post(GET_STATIC_QR, "", {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllFRMUsers = async (mid, token) => {
  try {
    const res = await axios.get(`${FETCH_FRM_USERS}${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllFunctionForApiCalls = async (URL, token) => {
  try {
    console.log(URL);
    const res = await axios.get(URL, {
      headers: {
        Authentication: "Bearer " + token,
      },
      // params: {
      //   page,
      //   size,
      // },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserData = async (payload, token) => {
  try {
    const response = await axios.put(`${USER_UPDATE}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
